import {BrowserRouter, Route, Routes} from "react-router-dom";
import MainPage from "./pages/MainPage";
import RedirectToAppV2 from "./pages/RedirectToAppV2";
import LoginPage from "./pages/LoginPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        {/*<Route exact path="/s/:code" element={<RedirectToApp />} />*/}
        <Route exact path="/r/:hash/:code"  element={<RedirectToAppV2 />} />
        <Route exact path="/login"  element={<LoginPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
