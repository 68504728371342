import React from 'react'
import {MdOutlineLocalPlay} from 'react-icons/md'

const CardCoupon = ({title,amount}) => {
  return (
    <div className='flex bg-white rounded-[16px] p-4 shadow-sm w-full'>
      <div className="flex flex-row items-center w-full gap-2">
        <div className='flex flex-col items-start flex-1'>
          <span className='font-bold uppercase text-[16px]'>{title}</span>
          <span className='text-neutral-500 dark:text-neutral-300 text-[13px]'>cupones acumulados</span>
        </div>
        <span className='font-bold'>{amount}</span>
        <MdOutlineLocalPlay size={28} />
      </div>
    </div>
  )
}

export default CardCoupon