import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {Button, Input} from "../components/atoms";
import {PiCheckCircleFill, PiConfetti, PiShieldWarningBold, PiWarningCircleFill, PiSpinner} from "react-icons/pi";

const baseURL = 'https://api5.wargos.cloud'

const LoginPage = () => {
  const navigate = useNavigate();

  const salaId = localStorage.getItem("s")
  const logo = localStorage.getItem("logo")
  const documentLs = localStorage.getItem("document")
  const customerName = localStorage.getItem("name")

  if (salaId === null || salaId === undefined || salaId === '') {
    navigate("/")
  }

  const [response, setResponse] = useState('')
  const [document, setDocument] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingQr, setLoadingQr] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();

  const validateCustomer = (document) => {
    setIsLoading(true);
    const params = {salaId: salaId, document: document}

    axios.get(baseURL + '/xcanje/coupons/summary', {params: params}).then((response) => {
      console.log('Cliente existe')
      //setResponse("Logueado exitosamente")
      setDocument(document)
      localStorage.setItem("document", document)
      localStorage.setItem("customerId", response.data.person.id)
      localStorage.setItem("name", response.data.person.firstName)
      loginQr(document);
    }).catch((error) => {
      setResponse("Debe registrarse con el personal de sala")
    }).finally(() => {
      setIsLoading(false);
    });

  }

  const logout = () => {
    localStorage.removeItem("document")
    localStorage.removeItem("name")
    localStorage.removeItem("customerId")
    setDocument('')
    setResponse('')
  }

  const loginQr = (doc) => {
    setLoadingQr(true)
    const body = {
      document: doc,
      code: searchParams.get('code'),
      salaId: salaId
    }
    axios.post(baseURL + '/xcustomerapp/qr-login-simple', body).then((response) => {
      localStorage.setItem("document", doc)
      setSuccess(true)
      setResponse('')
    }).catch((error) => {
      setResponse(error.response.data.message)
      setSuccess(false)
    }).finally(() => {
      setLoadingQr(false);
    });
  }

  useEffect(() => {
    if (documentIsValid()) {
      setDocument(documentLs)
      loginQr(documentLs);
    }
  }, []);

  const documentIsValid = () => {
    return documentLs !== null && documentLs !== undefined && documentLs !== ''
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (document) {
      validateCustomer(document);
    } else {
      console.error('Documento no válido');
    }
  }

  return (
    <div className="w-full h-[100vh] flex flex-col items-center justify-center p-8 gap-4 relative">
      <img src={logo} alt="logo" className="max-w-[120px] min-w-[120px] absolute top-4" />
      {!documentIsValid() ?
        <form onSubmit={handleSubmit} className="flex flex-col items-center w-full gap-4">
          <div className="flex flex-col items-center w-full gap-1">
            <span className="">Ingrese su documento</span>
            <Input type="number" className='text-xl text-center' value={document} required
              onChange={(e) => setDocument(e.target.value)} />
          </div>
          <Button type="submit" className='w-full' disabled={isLoading}>{isLoading ? 'Cargando' : 'Ingresar'}</Button>
          {response !== '' && !isLoading &&
            <div className="flex flex-col items-center gap-2">
              <PiWarningCircleFill size={40} className="text-amber-600" />
              <span className="text-sm text-center">{response}</span>
            </div>
          }
        </form>
        :
        <div className="flex flex-col items-center w-full gap-4">
          {loadingQr ?
            <div className="flex flex-col items-center gap-2">
              <PiSpinner size={60} className="animate-spin" />
              <div className="flex flex-col items-center gap-2 text-center">
                <span className="text-[16px]">Cargando</span>
              </div>
            </div>
            :
            (success ?
              <>
                <div className="flex flex-row gap-1">
                  <span>Bienvenido,</span>
                  <strong>{customerName}</strong>
                </div>
                <div className="flex flex-col items-center gap-2">
                  <PiCheckCircleFill size={100} className="text-green-600" />
                  <div className="flex flex-row items-center gap-2 text-center">
                    <span>Sesión iniciada</span>
                    <PiConfetti size={20} />
                  </div>
                </div>
                <Button onClick={() => navigate('/')}>Ver cupones</Button>
              </>
              :
              <div className="flex flex-col items-center gap-2">
                <PiShieldWarningBold size={100} className="text-amber-600" />
                <div className="flex flex-col items-center gap-2 text-center">
                  <span>{response}</span>
                  <strong>{'Documento: ' + document}</strong>
                  {/*<strong>{'QR: ' + searchParams.get('code')}</strong>*/}

                  <Button onClick={() => loginQr(document)}>Reintentar</Button>
                </div>
              </div>
            )
          }
          <Button variant='outline' onClick={logout} className='mt-32'>Cambiar de documento</Button>
        </div>
      }
    </div>
  )
}

export default LoginPage
