import React, {useEffect, useState} from "react";
import {MdOutlineQrCodeScanner} from "react-icons/md";
import {CardCoupon} from "../components/blocks";
import axios from "axios";
import {PiSpinner} from "react-icons/pi";

const MainPage = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);

  const fetchData = async () => {
    const customerId = localStorage.getItem("customerId");
    const salaId = localStorage.getItem("s");

    if (customerId && salaId) {
      try {
        setIsLoading(true);
        const response = await axios.get(`https://api4.wargos.cloud/xpointcore/customers/${customerId}/qr/point-coupons?salaId=${salaId}`);
        setCoupons(response.data)
      } catch (error) {
        console.error("Error al obtener cupones:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`flex flex-col gap-8 w-full h-[100vh] items-center text-center bg-neutral-100
        ${coupons.length > 0 ? 'pt-8' : 'justify-center'}
      `}>
      <span className="text-[16px]">Escanee un codigo QR para empezar</span>
      <MdOutlineQrCodeScanner size={80} />
      {coupons.length > 0 ?
        <div className="flex flex-col w-full" style={{height: 'calc(100vh - 224px)', overflowY: "auto"}}>
          <span className="text-[16px]">Cupones acumulados el dia de hoy</span>
          {isLoading ?
            <div className="flex flex-col items-center gap-2">
              <PiSpinner size={40} className="animate-spin" />
              <div className="flex flex-col items-center gap-2 text-center">
                <span className="text-[16px]">Cargando</span>
              </div>
            </div>
            :
            <div className="flex flex-col w-full gap-3 p-4 overflow-y-auto">
              {coupons.map(coupon => (
                <CardCoupon key={coupon.couponTypeId} title={coupon.couponName} amount={coupon.quantity} />
              ))}
            </div>
          }
        </div>
        :
        <span className="text-[16px]">Acumula cupones y gana con nuestras promociones!</span>
      }
    </div>
  )
}

export default MainPage
